<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.autonomous_teaching_activity.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="form-description rich-text-content mb-2"
        v-html="$v.autonomous_teaching_activity.description.$model"
        @click="$bvModal.show(`edit-description-modal`)"
      ></div>
      <b-modal
        :id="`edit-description-modal`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="autonomous_teaching_activity"
          :Text="autonomous_teaching_activity.description"
          @save="patchDescription"
          @close="$bvModal.hide(`edit-description-modal`)"
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "AutonomousTeachingActivityForm",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  mixins: [validationMixin],
  props: {
    AutonomousTeachingActivity: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      autonomous_teaching_activity: { ...this.AutonomousTeachingActivity },
    };
  },
  validations: {
    autonomous_teaching_activity: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(5),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.autonomous_teaching_activity[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.autonomous_teaching_activity.$touch();
      if (this.$v.autonomous_teaching_activity.$anyError) {
        return;
      }
      if (isNaN(this.autonomous_teaching_activity.id)) {
        this.createAutonomousTeachingActivity();
      } else this.updateAutonomousTeachingActivity();
    },
    patchDescription(object, text) {
      this.autonomous_teaching_activity.description = text;
    },
    createAutonomousTeachingActivity() {
      this.$store
        .dispatch(
          names.POST_AUTONOMOUS_TEACHING_ACTIVITY,
          this.autonomous_teaching_activity
        )
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "mesh.autonomousteachingactivity",
              false,
              "Actividad de Trabajo Autónomo"
            ) + " creado."
          );
          this.$emit("created", response);
        });
    },
    updateAutonomousTeachingActivity() {
      this.$store
        .dispatch(
          names.UPDATE_AUTONOMOUS_TEACHING_ACTIVITY,
          this.autonomous_teaching_activity
        )
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "mesh.autonomousteachingactivity",
              false,
              "Actividad de Trabajo Autónomo"
            ) + " actualizado."
          );
          this.$emit("updated", response);
        });
    },
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.2rem 0.5rem;
  line-height: 1.5;
}
.form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>